const CLUSTER_ICON_HEIGHT = 42;
const CLUSTER_ICON_WIDTH = 42;
const ICON_PIN_HEIGHT = 60;
const ICON_PIN_WIDTH = 60;
const DEFAULT_ZOOM = 14;
const MAP_STYLE = [
  {
    elementType: "geometry",
    stylers: [{color: "#ffffff"}],
  },
  {
    featureType: "administrative",
    stylers: [{visibility: "off"}],
  },
  {
    featureType: "landscape",
    stylers: [{color: "#ffffff"}],
  },
  {
    featureType: "poi",
    stylers: [{visibility: "off"}],
  },
  {
    featureType: "transit",
    stylers: [{visibility: "off"}],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{color: "#eeeeee"}],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{color: "#dbdbdb"}],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{color: "#c4d7d4"}],
  }
];

const initFountainsMap = (mapContainerId, mapLocations, clusterIcon) => {

  const markerClusterOptions = {};
  setMarkerClusterOptions(markerClusterOptions, clusterIcon, CLUSTER_ICON_HEIGHT, CLUSTER_ICON_WIDTH);
  forceClusterImageDimensions(mapContainerId, CLUSTER_ICON_HEIGHT, CLUSTER_ICON_WIDTH);

  let bounds = new google.maps.LatLngBounds();
  let markers = [];
  let map;

  map = new google.maps.Map(document.getElementById('map-1'), {
    streetViewControl: false,
    clickableIcons: false,
    zoom: DEFAULT_ZOOM,
    mapTypeControl: false,
    styles: MAP_STYLE
  });

  const oms = new OverlappingMarkerSpiderfier(map, {
    markersWontMove: true,
    markersWontHide: true,
    circleFootSeparation: 50,
    spiralFootSeparation: 50,
    legWeight: 2
  });

  const icon = {
    scaledSize: new google.maps.Size(ICON_PIN_HEIGHT, ICON_PIN_WIDTH),
  };

  mapLocations.forEach((mapLocation, i) => {
    const {lat: mapLat, lng: mapLng, markerImg, iconUrl} = mapLocation;

    ((mapLat, mapLng, markerImg) => {
      icon.url = iconUrl;

      const marker = new google.maps.Marker({
        position: {lat: mapLat, lng: mapLng},
        map,
        icon: icon,
        draggable: false
      });

      google.maps.event.addListener(marker, 'spider_click', () => {
        map.setCenter(marker.getPosition());
        map.setZoom(map.getZoom() + 1);
      });

      oms.addMarker(marker);
      bounds.extend(marker.position);
      markers.push(marker);

      const listener = google.maps.event.addListenerOnce(map, "idle", () => {
        if (map.getZoom() > DEFAULT_ZOOM) map.setZoom(DEFAULT_ZOOM);
        google.maps.event.removeListener(listener);
      });
    })(mapLat, mapLng, markerImg);
  });

  if (mapLocations.length > 0) {
    map.fitBounds(bounds); //map centered on average marker positions
  }

  const cluster = new MarkerClusterer(map, markers, markerClusterOptions); //init marker cluster

  google.maps.event.addListener(cluster, 'clusterclick', (clickedCluster) => {
    if (map) {
      map.setCenter(clickedCluster.getCenter());
      map.setZoom(map.getZoom() + 1);
    }
  });

};

const setMarkerClusterOptions = (markerClusterOptions, clusterIcon, height, width) => {
  markerClusterOptions.zoomOnClick = true;
  markerClusterOptions.maxZoom = DEFAULT_ZOOM;
  markerClusterOptions.styles = [
    {
      url: clusterIcon,
      textColor: 'white',
      textSize: 20,
      textLineHeight: 2,
      fontFamily: 'Roboto, sans-serif',
      height: height,
      width: width,
    }
  ];
}

const forceClusterImageDimensions = (mapContainerId, height, width) => {
  const styleElement = document.createElement('style');
  const selector = mapContainerId ? `#${mapContainerId} .cluster img` : '.cluster img';
  styleElement.textContent = `${selector} { height: ${height}px; width: ${width}px; }`;
  document.head.appendChild(styleElement);
}